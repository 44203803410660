import React from 'react';
import Profile from '../assets/profile.png';
import Typical from 'react-typical';
import img from '../assets/img_prfl_to_mobile.png';

const ProfileCard: React.FC = () => {
  return (
    <>
      <div className="flex items-center justify-center  flex-col min-h-screen">
        <div className="w-[%80] max-w-[656px] max-sm:p-7 relative">
          <div
            className="hidden md:flex  h-0.5 absolute bg-ui-green ml-[180px]"
            style={{ width: 'calc(100% - 180px)' }}
          />
          <div className="flex md:flex-row flex-col items-center md:items-end ">
            <img
              src={Profile}
              alt="Profile"
              className="w-[200px] h-[180px] rounded-tl-[15px] mb-4 hidden md:flex"
            />
            <img
              src={img}
              alt="Profile"
              className="w-24 h-24 rounded-full mb-4 flex md:hidden"
            />

            <div
              className="md:pl-8 w-full 
          max-sm:flex 
          max-sm:flex-col 
          max-sm:items-center 
          max-sm:justify-center"
            >
              <h2 className="prfl-0 text-ui-green font-bold ">Hamdi Huntürk</h2>
              <div
                className="text-ui-green font-normal mb-4 md:text-xl md:mb-[9.5px] md:relative
                          "
              >
                <Typical
                  loop={Infinity}
                  wrapper="span"
                  steps={[
                    'Electric Electrical Engineer',
                    2000,
                    'Mobile Developer',
                    1500,
                    'Backend Developer',
                    1500,
                  ]}
                />
              </div>
            </div>
          </div>
          <p className="text-white font-medium text-base text-justify ">
            I am currently in my final year of Electrical and Electronics
            Engineering at Ondokuz Mayıs University, with over two years of
            hands-on experience in{' '}
            <span className=" text-ui-pink font-extrabold">
              Mobile application development
            </span>
            ,{' '}
            <span className=" text-ui-pink font-extrabold">
              Backend programming
            </span>
            , and{' '}
            <span className=" text-ui-pink font-extrabold">
              Embedded software
            </span>
            . I have a passion for project management and problem-solving,
            focusing on delivering high-quality software solutions. Outside of
            my professional work, I enjoy fitness, exploring philosophical
            concepts, camping, and engaging in various activities that broaden
            my perspective and well-being. I am committed to lifelong learning
            and staying up-to-date with the latest technological advancements.
          </p>

          <div className="w-full h-0.5 mt-6 bg-ui-green box-border" />
        </div>
      </div>
    </>
  );
};

// const ProfileCard2: React.FC = () => {
//   return (
//     <div className="flex items-center justify-center min-h-screen bg-ui-dark-blue">
//       <div className="rounded-lg p-6 w-[%80] max-w-[656px] text-center">
//         <div className="flex flex-col items-center">
//           <img
//             src={img}
//             alt="Profile"
//             className="w-24 h-24 rounded-full mb-4"
//           />
//           <h2 className="text-2xl text-ui-green font-bold mb-2">
//             Hamdi Huntürk
//           </h2>
//           <h3 className="text-ui-green font-normal mb-4">
//             Electric Electrical Engineer
//           </h3>
//         </div>

//         <p className="text-white font-medium text-base text-justify">
//           I am currently in my final year of Electrical and Electronics
//           Engineering at Ondokuz Mayıs University, with over two years of
//           hands-on experience in{' '}
//           <span className=" text-ui-pink font-extrabold">
//             Mobile application development
//           </span>
//           ,{' '}
//           <span className=" text-ui-pink font-extrabold">
//             Backend programming
//           </span>
//           , and{' '}
//           <span className=" text-ui-pink font-extrabold">
//             embedded software
//           </span>
//           . I have a passion for project management and problem-solving,
//           focusing on delivering high-quality software solutions. Outside of my
//           professional work, I enjoy fitness, exploring philosophical concepts,
//           camping, and engaging in various activities that broaden my
//           perspective and well-being. I am committed to lifelong learning and
//           staying up-to-date with the latest technological advancements.
//         </p>
//       </div>
//     </div>
//   );
// };

export default ProfileCard;
