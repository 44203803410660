import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import styled from 'styled-components';
import Profile from './assets/profile.png';
import Yavrum from './assets/yavrum.jpeg';
interface HeartProps {
  size: number;
  delay: number;
}

const Heart: React.FC<HeartProps> = ({ size, delay }) => {
  // Rastgele bir yöne doğru hareket için açı hesapla
  const angle = Math.random() * 2 * Math.PI; // 0 ile 360 derece arasında rastgele açı
  const distance = Math.random() * 500 + 200; // Kalplerin yayılma mesafesi

  return (
    <motion.div
      style={{
        position: 'absolute',
        fontSize: `${size}px`,
        color: 'red',
        filter: 'blur(1px) drop-shadow(0 0 5px rgba(255, 0, 0, 0.8))',
        left: '40%', // Başlangıçta ortada
        top: '40%', // Başlangıçta ortada
      }}
      animate={{
        x: [0, Math.cos(angle) * distance], // X ekseninde yayılma
        y: [0, Math.sin(angle) * distance], // Y ekseninde yayılma
        opacity: [1, 0.8, 0], // Yavaşça kaybolma
        scale: [1, 1.5, 0.5], // Büyüyüp küçülme
      }}
      transition={{
        duration: 3,
        delay,
        repeat: Infinity,
        repeatType: 'mirror',
      }}
    >
      ❤
    </motion.div>
  );
};

const CardContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #ff6b6b, #ff3838);
  padding: 20px 40px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.6);
  text-align: center;
`;

const Text = styled.h1`
  color: white;
  font-family: 'Pacifico', cursive;
  font-size: 45px;
  margin: 0;
`;

const Heart2: React.FC<HeartProps> = ({ size, delay }) => {
  // Rastgele bir yöne doğru hareket için açı hesapla
  const angle = Math.random() * 2 * Math.PI; // 0 ile 360 derece arasında rastgele açı
  const distance = Math.random() * 500 + 200; // Kalplerin yayılma mesafesi

  return (
    <motion.div
      style={{
        position: 'absolute',
        fontSize: `${size}px`,
        color: 'red',
        filter: 'blur(1px) drop-shadow(0 0 5px rgba(255, 0, 0, 0.8))',
      }}
      animate={{
        x: [0, Math.cos(angle) * distance], // X ekseninde yayılma
        y: [0, Math.sin(angle) * distance], // Y ekseninde yayılma
        opacity: [1, 0.8, 0], // Yavaşça kaybolma
        scale: [1, 1.5, 0.5], // Büyüyüp küçülme
      }}
      transition={{
        duration: 3,
        delay,
        repeat: Infinity,
        repeatType: 'mirror',
      }}
    >
      ❤
    </motion.div>
  );
};
const Card: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [flashBgColor, setFlashBgColor] = useState<string>('');
  const [showImages, setShowImages] = useState<boolean>(false);
  const [positions, setPositions] = useState<{ top: string; left: string }[]>(
    []
  );
  const noMessages = [
    'HAYIRRR',
    'KABUL EDİLMİYORR',
    'OLMAZ',
    'ASLA',
    'YANLIŞ SEÇİM',
    'TEKRAR DENE',
    'SUS',
  ];

  const handleYesClick = () => {
    setMessage('Seni sonsuzzzz seviyorum 💖');
    // 5 farklı rastgele konum oluştur
  };

  const handleNoClick = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    if (isDialogOpen) {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter >= 10) {
          clearInterval(interval);
          setIsDialogOpen(false);
          return;
        }
        setFlashBgColor(counter % 2 === 0 ? 'bg-red-500' : 'bg-white');
        counter++;
      }, 300);

      // 3 saniye sonra otomatik kapatma
      setTimeout(() => {
        setIsDialogOpen(false);
      }, 3000);
    }
  }, [isDialogOpen]);

  const hearts = Array.from({ length: 20 }, (_, i) => (
    <Heart key={i} size={Math.random() * 100 + 20} delay={Math.random() * 3} />
  ));

  return (
    <>
      {message ? (
        <>
          <div className="h-full w-full bg-red-200">
            {hearts}
            {/* <img
              src={Profile}
              alt="Profile2"
              className="w-[200px] h-[180px]  mb-4 "
            /> */}

            <img
              src={Profile}
              alt="Valentine"
              className="w-24 h-24 fixed animate-spin rounded-full"
              style={{
                top: `10%`,
                left: `10%`,
              }}
            />
            <img
              src={Profile}
              alt="Valentine"
              className="w-24 h-24 fixed animate-ping rounded-full"
              style={{
                top: `10%`,
                left: `70%`,
              }}
            />
            <img
              src={Profile}
              alt="Valentine"
              className="w-24 h-24 fixed animate-ping rounded-full"
              style={{
                top: `80%`,
                left: `10%`,
              }}
            />
            <img
              src={Profile}
              alt="Valentine"
              className="w-24 h-24 fixed animate-spin rounded-full"
              style={{
                top: `80%`,
                left: `70%`,
              }}
            />
            <div className="w-full h-full flex items-center justify-center origin-center">
              {' '}
              <img
                src={Yavrum}
                alt="Valentine"
                className="w-36 h-44 fixed rounded-2xl border-red-300 border-4 shadow-[0_0_20px_5px_rgba(255,0,0,0.6)] backdrop-blur-lg"
                style={{}}
              />
            </div>

            <div className="w-full flex items-center justify-center origin-center font-pacifico">
              <h2 className="top-[60%] mt-4 w-56  text-2xl text-center fixed text-white bg-red-400 px-6 py-3 rounded-lg shadow-lg animate-bounce">
                {message}
              </h2>
            </div>
          </div>
        </>
      ) : (
        <CardContainer>
          <Text>Do you wanna be my Valentine?</Text>

          <div className="valentine-container bg">
            <div className="buttons">
              <button className="no-button" onClick={handleNoClick}>
                ✗
              </button>
              <button className="yes-button" onClick={handleYesClick}>
                ✓
              </button>
            </div>
          </div>
        </CardContainer>
      )}

      {/* Kırmızı yanıp sönen tam ekran dialog */}
      {isDialogOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center ${flashBgColor} transition-all duration-300`}
        >
          <div className="bg-white p-6 rounded-lg shadow-xl text-center">
            <h2 className="text-xl font-bold">
              {noMessages[Math.floor(Math.random() * noMessages.length)]}
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export { Card, Heart };
