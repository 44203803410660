import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CV from './cv'; // Ana sayfa bileşeni
import Love from './love'; // /love için yeni bileşen

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CV />} /> {/* Anasayfa */}
        <Route path="/love" element={<Love />} /> {/* /love sayfası */}
      </Routes>
    </Router>
  );
}

export default App;
