import styled from 'styled-components';
import { Card, Heart } from './dd';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  // background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  overflow: hidden;
`;

const Love: React.FC = () => {
  const hearts = Array.from({ length: 20 }, (_, i) => (
    <Heart key={i} size={Math.random() * 40 + 20} delay={Math.random() * 2} />
  ));

  return (
    <>
      <Container className="body.love-page">
        <div className="left-0"> {hearts}</div>
        {hearts}
        <Card />
      </Container>
    </>
  );
};

export default Love;
